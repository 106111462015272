.wrapper {
  padding: 10px 10px 12px;
  border-radius: 3px;
  margin-bottom: 1em;

  h3 {
    font-family: "IsidoraSans-SemiBold", sans-serif;
    font-size: var(--font-20);
    margin: 0;
  }
}

.low {
  background-color: #EEF4FF; 
  border: 1px solid #C7D7FE;
}

.high {
  background-color: #FFFAEB;
  border: 1px solid #FEDF89;
}

.content {
  font-size: var(--font-18);
}