.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;

  img {
    width: 52px;
    height: 18px;
    margin-left: 5px;
  }

  & > div {
    width: 50%;
  }
}

.logo {
  height: 50px;
  background-image: url('../../assets/blodtrycksdoktorn.svg');
  background-repeat: no-repeat;
  background-position: 0 center;
  justify-content: flex-end;
  background-size: contain;
}

.partner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  img {
    max-width: 100px;
    height: auto;
  }
}

.privacyPolicy {
  font-size: var(--font-12);
}

.genericError {
  padding: 10px 10px 12px;
  border-radius: 3px;
  background-color: #FFFAEB;
  border: 1px solid #FEDF89;
}