input,
button,
select,
textarea {
  font-size: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;

  legend {
    font-size: var(--font-16);
    font-weight: 600;
    margin-bottom: 14px;
  }

  label {
    display: block;
  }
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;

  + .error-message {
    margin-left: 5px;
  }
}

input:disabled {
  background-color: var(--disabled-input-background-color);
}

input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  height: 40px;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius);

  &.error {
    border-color: var(--error-color);
  }
}

input[type='text'], input[type='number'], input[type='email'] {
  width: 100%;
  margin-bottom: 20px;

  &:focus {
    outline: 1px solid var(--primary-color);
    box-shadow: 0px 0px 0px 1px var(--white), 0px 0px 0px 4px var(--button-focus-halo-color);
  }

  & + p a {
    font-size: var(--font-12);
  }
}

form {
  > div,
  fieldset {
    margin-bottom: 30px;
  }

  textarea {
    min-height: 130px;
  }
}

input[type='radio'] {
  position: absolute;
  opacity: 0;
  visibility: visible;

  &:focus {
    + label:before {
      border: 2px solid #000000;
    }
  }

  &:checked + label {
    &:before {
      border: 4px solid #E6E6E6;
      background: var(--brand-main);
      box-shadow: none;
    }
  }

  & + label {
    cursor: pointer;
    display: flex;
  }

  & + label:before {
    display: inline-block;
    position: relative;
    content: '';
    height: 24px;
    width: 24px;
    top: 2px;
    margin-right: 10px;
    background-color: #E6E6E6;
    border-radius: 50%;
  }

  &[disabled] + label:before {
    background: #cccccc;
    opacity: 0.6;
  }
}

input[type="email"] + p > a {
  font-size: var(--font-12);
}