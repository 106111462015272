@import '../../styles/_mixins.scss';

.controls {
  position: absolute;
  display: block;
  width: calc(100% - 2rem);

  div {
    display: flex;
    justify-content: space-between;
  }
}

.logo {
  width: 150px;
  height: 48px;
  background-image: url('../../assets/blodtrycksdoktorn.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

@include respond(desktop) {
  .controls {
    width: calc(100% - 80px - 2rem);
  }
}