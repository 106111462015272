.label {
  font-size: 1.125rem;
  padding: 10px 0;
  margin: 0;
  
  &:not(:last-child) {
    border-bottom: 1px solid var(--option-divider-color);

  }
}

.disabled {
  pointer-events: none;
}