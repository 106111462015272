.results {
  margin-top: 70px;
  
  .diagram {
    position: relative;
    margin: 0 auto;
    width: 243px;
    height: 120px;
    background-image: url('../../assets/diagram_bg.png');
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
    overflow: hidden;
    margin-bottom: 2em;

    .dial {
      width: 87px;
      height: 23px;
      position: absolute;
      bottom: -12px;
      left: 39px;
      background-image: url('../../assets/needle.png');
      background-repeat: no-repeat;
      background-size: contain;
      transform-origin: 80px 12px;
      animation: setangle, jitter;
      animation-duration: 0.6s, 0.4s;
      animation-delay: 0.3s, 0.9s;
      animation-timing-function: ease-in, ease-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }


  .riskEvaluation {
    display: block;
    text-align: center;
    font-family: 'IsidoraSans-Bold', sans-serif;
    font-size: var(--font-18);
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.getStarted {
  background-color: var(--tertiary-color);
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 30px 20px;

  h3 {
    margin-top: 0;
  }
}

.getStartedStep {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.stepCount {
  --size: 32px;

  margin-right: 8px;
  color: var(--white);
  background-color: var(--brand-main);
  font-size: var(--font-14);
  font-weight: bold;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  & + div {
    flex: 1;
  }
}

.low {
  color: #0D9D1D;
}

.high {
  color: #E50000;
}

@keyframes setangle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(var(--dialAngle));
  }
}

@keyframes jitter {
  0% {
    transform: rotate(var(--dialAngle))
  }
  20% {
    transform: rotate(calc(var(--dialAngle) + 5deg))
  }
  40% {
    transform: rotate(calc(var(--dialAngle) - 3deg))
  }
  60% {
    transform: rotate(calc(var(--dialAngle) + 2deg))
  }
  80% {
    transform: rotate(calc(var(--dialAngle) - 1deg))
  }
  100% {
    transform: rotate(var(--dialAngle))
  }
}