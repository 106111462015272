.wrapper {
  position: relative;
  text-align: right;

  button {
    cursor: pointer;
    background-color: unset;
    color: var(--primary-text-color);
    padding: 0;

    svg {
      margin-right: 0;
    }
  }
}

.selectedLanguage {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  
  img {
    height: 15px;
    width: auto;
  }
}

.flagSE::before {
  background-image: url('../../assets/se.png');    
}

.flagGB::before {
  background-image: url('../../assets/gb.png');    
}

.mobile {
  display: inline-flex;
  align-items: center;

  img {
    height: 15px;
    width: auto;
    object-fit: contain;
  }

  select {
    border: none;
    background-color: transparent;
  }
}

.optionsWrapper {
  position: absolute;
  width: 100px;
  border: 1px solid #bcbcbc;
  border-radius: var(--border-radius);
  background-color: #ffffff;
  width: 210px;
  margin-top: 0;
  padding-inline-start: 0;
  right: 0;
  top: 100%;
  opacity: 0;
  transition: all 0.1s ease-in-out;

  &.open {
    top: calc(100% + 5px);
    opacity: 1;
  }

  button {
    width: 100%;
    padding: 10px 20px;
    border-radius: 0;

    &:not(:last-child) {
      border-bottom: 1px solid #bcbcbc;
      margin-bottom: 0;
    }
  }

  button::before {
    content: '';
    height: 20px;
    margin-right: 20px;
    min-width: 20px;
    position: relative;
    display: flex;
    top: 1px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 10px;
  }
}