.ratingWrapper {
  display: flex;
  align-items: center;
  width: min-content;
  padding: 8px 16px;
  background-color: #F4F4F5;
  border-radius: 100px;
  white-space: nowrap;
}

.stars {
  width: 87px;
  height: 15px;
  background-image: url('../../assets/stars.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 14px;
}

.extraInfo {
  margin-top: 3px;
  font-size: var(--font-12);

  strong {
    font-family: "IsidoraSans-SemiBold", sans-serif;
  }
}