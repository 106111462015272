.analysis {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 10rem;

  div {
    margin-top: 2rem;
  }
}