@import './_reset.scss';
@import './_mixins.scss';
@import './forms.scss';
@import './values.scss';

@font-face {
  font-family: 'IsidoraSans-Bold';
  src: url('../assets/fonts/34C90D_7_0.eot'), url('../assets/fonts/34C90D_7_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/34C90D_7_0.woff2') format('woff2'), url('../assets/fonts/34C90D_7_0.woff') format('woff'),
    url('../assets/fonts/34C90D_7_0.ttf') format('truetype');
}
@font-face {
  font-family: 'IsidoraSans-Regular';
  src: url('../assets/fonts/34C90D_12_0.eot'), url('../assets/fonts/34C90D_12_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/34C90D_12_0.woff2') format('woff2'), url('../assets/fonts/34C90D_12_0.woff') format('woff'),
    url('../assets/fonts/34C90D_12_0.ttf') format('truetype');
}
@font-face {
  font-family: 'IsidoraSans-SemiBold';
  src: url('../assets/fonts/34C90D_16_0.eot'), url('../assets/fonts/34C90D_16_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/34C90D_16_0.woff2') format('woff2'), url('../assets/fonts/34C90D_16_0.woff') format('woff'),
    url('../assets/fonts/34C90D_16_0.ttf') format('truetype');
}

::placeholder {
  color: var(--secondary-text-color);
}

html,
body {
  position: relative;
  background: var(--white);
}

#root {
  display: flex;
  justify-content: center;
}

main {
  position: relative;
  width: 100%;
  max-width: 450px;
  overflow: hidden;
}

@include respond(desktop) {
  main {
    max-width: 530px;
    padding: 0 40px;
  }
}

.wrapper {
  height: 100%;
  margin: 0 auto;
  padding: 1rem;

  section {
    height: 100%;
  }
}

body {
  color: var(--primary-text-color);
  margin: 0;
  font-family: var(--body-font);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  text-decoration: underline;
}

ul,
ol {
  margin-top: 1em;
  padding-inline-start: 1em;

  li {
    position: relative;
  }

  li:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

ul li {
  display: inline-flex;

  &::before {
    content: '';
    position: relative;
    top: 3px;
    min-width: 20px;
    height: 20px;
    display: block;
    background-image: url('../assets/checkmark.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 20px;
  }
}

ul {
  list-style-type: none;
}

p:not(:last-child) {
  margin-bottom: 1.25em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  margin: 0.5em 0;
}

h1 {
  font-size: var(--font-26);
}

header h2 {
  margin: 0.5em 0;
}

h3 {
  font-size: var(--font-22);
}

p,
li {
  font-size: var(--font-16);
}

p a {
  font-size: inherit;
}

.error-message {
  font-size: var(--font-12);
  color: var(--error-color);
}

.info-message {
  font-size: var(--font-12);
  color: var(--secondary-text-color);
}

button,
.button-link {
  border: 0;
  display: inline-flex;
  align-items: center;
  padding: 12px 24px;
  border-radius: 100px;
  color: var(--white);
  background-color: var(--brand-main);
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;

  &[disabled] {
    user-select: none;
    pointer-events: none;
    color: var(--disabled-button-color);
    background-color: var(--disabled-button-background-color);
  }

  &.text-button {
    font-family: 'IsidoraSans-Bold', sans-serif;
    padding: 0;
    background-color: transparent;
    color: var(--brand-main);
  }

  &.secondary {
    background-color: #e6d3dc;
    color: var(--brand-main);
  }

  &.full-width {
    width: 100%;
    justify-content: center;
  }

  svg {
    margin-right: 8px;
  }

  img {
    height: 20px;
    width: 20px;
    margin-left: 10px;
  }
}

.center-button {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-explainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  &.margin-offset {
    margin-top: -25px;
  }
}

img {
  width: 100%;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mr-10 {
  margin-right: 10px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.question-header {
  margin-top: 6rem;

  & + form {
    margin-top: 2rem;
  }

  h2 {
    margin-top: 0;
  }

  small {
    display: inline-block;
    margin-bottom: 5px;
    font-size: var(--font-13);
    color: #4d4d4d;
  }
}

.cookie-buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include respond(desktop) {
    display: block;
    width: auto;
  }
}

.check-heading {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.stack {
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: 1.25em;
  }
}
