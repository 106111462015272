.wrapper {
  background-image: url('../../assets/accumbo-spinner.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  animation: spin 1.5s linear infinite;  
}

.large {
  height: 100px;
  width: 100px;
}

.small {
  height: 50px;
  width: 50px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}