:root {
  /* colors */
  --white: hsl(0, 0%, 100%);
  --brand-main: hsl(331, 92%, 23%);
  --primary-color: hsl(333, 81%, 36%);
  --secondary-color: hsl(332, 100%, 95%);
  --tertiary-color: hsl(342, 63%, 95%);
  --primary-text-color: hsl(225, 6%, 14%);
  --secondary-text-color: hsl(0, 0%, 65%);
  --input-border-color: hsl(0, 0%, 80%);
  --primary-disabled-color: hsl(0, 0%, 65%);
  --primary-hover-color: hsl(333, 82%, 30%);
  --primary-active-color: hsl(333, 81%, 25%);
  --secondary-hover-color: hsl(333, 47%, 96%);
  --secondary-active-color: hsl(334, 47%, 90%);
  --button-focus-halo-color: hsl(334, 47%, 90%);
  --disabled-input-background-color: hsl(0, 0%, 96%);
  --disabled-button-background-color: hsl(240, 5%, 96%);
  --disabled-button-color: hsl(240, 5%, 79%);
  --error-color: hsl(357, 76%, 49%);
  --error-background-color: hsl(5, 86%, 97%);
  --success-color: hsl(155, 97%, 24%);
  --success-background-color: hsl(145, 81%, 96%);
  --option-divider-color: hsl(0, 0%, 90%);

  /* typography */
  --body-font: 'IsidoraSans-SemiBold', sans-serif;
  --heading-font: 'IsidoraSans-SemiBold', sans-serif;
  --font-12: 0.75rem;
  --font-13: 0.8125rem;
  --font-14: 0.875rem;
  --font-16: 1rem;
  --font-18: 1.125rem;
  --font-20: 1.25rem;
  --font-22: 1.375rem;
  --font-26: 1.625rem;

  /* other */
  --border-radius: 6px;
  --border-radius-sm: 3px;
}
