.wrapper {
  margin-top: 1em;
  border: 1px solid hotpink;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e2e2e2;
  background-color: #f8f8f8;
  padding: 32px;
  color: #635F61;
  border-radius: var(--border-radius);

  p {
    text-align: center;
  }

  h2 {
    color: var(--primary-text-color);
  }

  img {
    width: 100px;
    height: 94px;
  }

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
}